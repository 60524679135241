import React from 'react';
import { useTranslation } from 'react-i18next';

const news = [
  {media: 'news-sample-1.jpg', text: 'Сегодня третий, завершающий день Международной агропромышленной выставки - «Агро Экспо 2023».От имени компании благодарим организаторов и участников выставки 🙌'},
  {media: 'news-sample-2.jpg', text: 'Второй день Выставки-ярмарки сельскохозяйственной прдукции и отечественных производителей «Алтын күз 2023»'},
  {media: 'news-sample-3.jpg', text: 'Уважаемые соотечественники, в городе Бишкек одновременно проходят 2 выставки-ярмарки. Наша компания участвует в обеих выставках. Приглашаем всех посетить наши стенды 🙌ВЫСТАВКА – ЯРМАРКА СЕЛЬСКОХОЗЯЙСТВЕННОЙ ПРОДУКЦИИ и ОТЕЧЕСТВЕННЫХ ПРОИЗВОДИТЕЛЕЙ«АЛТЫН КҮЗ 2023»'}
]

const News = () => {
  const { t } = useTranslation();
  return (
    <div className='news home__section'>
      <div className='container'>
        <div className='home__section__title news__title'>
          {t('news.title')}
        </div>
        <a href='/' className='news__instagram'>
          <div className='news__instagram__wrapper'>
            <div className='news__instagram__media' />
            <div className='news__instagram__content'>
              <div className='news__instagram__content__name'>
                bai.elim
              </div>
              <div className='news__instagram__content__followers'>
                25k {t('news.subscribers')}
              </div>
            </div>
          </div>
        </a>
        <div className='news__list'>
          {news.map((item, index) => {
            return (
              <div key={index} className='news__item'>
                <div className='news__item__media'>
                  <img src={`/img/${item.media}`} className='news__item__media__img' alt={item.text} />
                </div>
                <div className='news__item__text inter-paragraph f-s-18'>
                  {t(`news.item${index+1}`)}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
};

export default News;