import React, {useEffect} from 'react';
import '../assets/styles/home.scss';
import Page from '../components/Page';
import News from '../components/home/News';
import Hero from '../components/home/Hero';
import Lines from '../components/home/Lines';
import Companies from '../components/home/Companies';
import History from '../components/home/History';
import Products from '../components/home/Products';
import Points from '../components/home/Points';

const Home = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#products') {
      const element = document.getElementById('products');
      if (element) {
        window.setTimeout(() => {
          window.history.replaceState({}, document.title, window.location.pathname + window.location.search);
          element.scrollIntoView({ behavior: 'smooth' });
        }, 300)
      }
    }
  }, []);

  return (
    <Page>
      <div className='home'>
        <Hero />
        <Lines />
        <History />
        <Companies />
        <Products />
        <Points />
        <News />
      </div>
    </Page>
  )
};

export default Home;