import React from 'react';
import { useTranslation } from 'react-i18next';

const items = [
  {media: 'lines-sample-1.jpg', text: 'Качество продукции', color: '#FF5C00'},
  {media: 'lines-sample-2.jpg', text: 'Устойчивое сельское хозяйство', color: '#008C44'},
  {media: 'lines-sample-3.jpg', text: 'Инновации', color: '#FF5C00'},
  {media: 'lines-sample-4.jpg', text: 'Сотрудничество', color: '#008C44'},
  {media: 'lines-sample-5.jpg', text: 'Ответственность', color: '#FF5C00'},
  {media: 'lines-sample-1.jpg', text: 'Обучение и развитие', color: '#FF5C00'},
  {media: 'lines-sample-3.jpg', text: 'Сообщество', color: '#FF5C00'},
]
const Lines = () => {
  const { t } = useTranslation();

  return (
    <div className='lines'>
      <div className='lines__list'>
        {items.map((item, index) => {
          return (
            <div key={index} className='lines__item'>
              <div className='lines__item__media' style={{background: `url(/img/home/lines/${item.media}) no-repeat 50% 50% / contain`}} />
              <div className='lines__item__text' style={{color: item.color}}>
                {t(`lines.item${index+1}`)}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
};

export default Lines;