import React from 'react';
import '../../assets/styles/founder.scss';
import { useTranslation } from 'react-i18next';

const Founder = () => {
  const { t } = useTranslation();
  return (
    <div className='founder'>
      <div className='founder__media' />
      <div className='founder__content'>
        <div className='founder__content__name'>
          {t('sustain.page.intro_name')}
        </div>
        <div className='founder__content__position'>
          {t('sustain.page.intro_position')}
        </div>
      </div>
    </div>
  )
};

export default Founder;