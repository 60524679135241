import React, {useEffect} from 'react';
import Header from './header/Header';
import Contact from  '../components/footer/Contact';
import Footer from  '../components/footer/Footer';
import Copyright from  '../components/footer/Copyright';
import { useLocation } from 'react-router-dom';

const Page = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    const hash = window.location.hash;
    console.log('sshash', hash);
    if (!hash) {
      console.log('ss1')
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return (
    <div className="page">
      <Header />
      <main className='main'>
        {children}
        <Contact />
        <Footer />
        <Copyright />
      </main>
    </div>
  )
};

export default Page