import React, {useEffect} from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';

import './assets/styles/styles.scss';

import Home from './pages/Home';
import Companies from './pages/Companies';
import About from './pages/About';
import Sustainability from './pages/Sustainability';
import Awards from './pages/Awards';
import { useTranslation } from 'react-i18next';

const App = () => {

  const { i18n } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem('locale');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);


  return (
    <div className='app'>
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/companies' element={<Companies />} />
          <Route path='/about' element={<About />} />
          <Route path='/sustainability' element={<Sustainability />} />
          <Route path='/awards' element={<Awards />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
