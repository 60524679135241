import React, { useState, useEffect } from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import icon from '../../assets/images/marker.png';

const containerStyle = {
  width: '100%',
  height: '100%'
};

const center = { lat: 42.829487, lng: 74.616571 };

const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const points = [
  { lat: 42.829487, lng: 74.616571 },
  { lat: 42.852491, lng: 74.560083 },
]


const Map = () => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey
  });

  const [map, setMap] = useState(null)

  const onLoad = React.useCallback(function callback(map) {
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])
  

  useEffect(() => {
    if (map) {
      map.setZoom(12);
      map.panTo(new window.google.maps.LatLng(center.lat, center.lng));
    }
  }, [map]);

  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={12}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {points.map((item, index) => {
          return (
            <Marker
              key={index}
              position={item}
              icon={{
                url: icon,
                scaledSize: new window.google.maps.Size(50, 50)
              }}
            />
          )
        })}
        
      </GoogleMap>
  ) : <></>
}

export default React.memo(Map)