import React from 'react';
import Page from '../components/Page';
import Hero from '../components/hero/Hero';
import Breadcrumbs from '../components/breadcrumbs/Breadcrumbs'
import '../assets/styles/about.scss';
import Founder from '../components/misc/Founder';

import { useTranslation } from 'react-i18next';

const About = () => {
  const { t, i18n } = useTranslation();

  const historyItems = [
    {media: 'history-1.jpg', text: t('about.page.history.item1')},
    {media: 'history-2.jpg', text: t('about.page.history.item2')},
    {media: 'history-3.jpg', text: t('about.page.history.item3')},
    {media: 'history-4.jpg', text: t('about.page.history.item4')},
  ]

  return (
    <Page>
      <div className='about-page'>
        <Hero
          media='hero-sample-about.jpg'
          title={t('about.page.hero_title')}
          lead={t('about.page.hero_lead')}
        />
        <div className='page-main'>
          <Breadcrumbs items={[{text: t('about.page.hero_title')}]} />
          <div className='container'>
            <div className='companies__lead'>
              {t('about.page.lead1')}<br/><br/>
              {t('about.page.lead2')}
            </div>
          </div>
          <div className='about-page__philosophy container'>
            <div className='about-page__philosophy__title'>
            {t('about.page.philosophy.title')}
            </div>
            <p className='about-page__philosophy__paragraph'>
            {t('about.page.philosophy.text1')}
            </p>
            <p className='about-page__philosophy__paragraph'>
            {t('about.page.philosophy.text2')}
            </p>
          </div>
          <div className='container'>
            <div className='about-page__history'>
              <div className='about-page__philosophy__title'>
              {t('about.page.history.title')}
              </div>
              <div className='about-page__history__items'>
                {historyItems.map((item, index) => {
                  return (
                    <div key={index} className='about-page__history__item'>
                      <div className='about-page__history__item__media'>
                        <div className='about-page__history__item__media__img' style={{background: `url(/img/history/${item.media}) no-repeat 50% 50% / cover`}} />
                      </div>
                      <div className='about-page__history__item__text'>
                        {item.text}
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className='about-page__history__column'>
                <div className='about-page__history__column__cell'>
                  <p className='about-page__history__column__paragraph'>
                    {t('about.page.column.text1')}
                  </p>
                  <p className='about-page__history__column__paragraph'>
                    {t('about.page.column.text2')}
                  </p>
                  <p className='about-page__history__column__paragraph'>
                    {t('about.page.column.text3')}
                  </p>
                </div>
                <div className='about-page__history__column__cell'>
                  <p className='about-page__history__column__paragraph'>
                    {t('about.page.column.text4')}
                  </p>
                  <p className='about-page__history__column__paragraph'>
                    {t('about.page.column.text5')}
                  </p>
                </div>
              </div>
            </div>
            <div className='about-page__mission'>
              <div className='about-page__mission__content'>
                <div className='about-page__mission__content__title'>
                  {t('about.page.mission.title')}
                </div>
                <p className='about-page__mission__content__paragraph'>
                  {t('about.page.mission.lead')}
                </p>
              </div>
              <div className='about-page__mission__media'>
                <img className='about-page__mission__media__img' src={'/img/mission.png'} alt='' />
              </div>
            </div>
            <div className='inner-page-section inner-page-section--white'>
              <div className='inner-page-section__media'>
                <img src={'/img/vision.jpg'} className='inner-page-section__media__img' alt=''/>
              </div>
              <div className='inner-page-section__content'>
                <div className='inner-page-section__content__title'>
                  {t('about.page.vision.title')}
                </div>
                <p className='inner-page-section__content__paragraph'>
                  {t('about.page.vision.lead')}
                </p>
              </div>
            </div>
            {i18n && (i18n.language == 'ru' || i18n.language == 'kg') && <div className='inner-page-section inner-page-section--green'>
              <div className='inner-page-section__media' />
              <div className='inner-page-section__content'>
                <div className='inner-page-section__content__title'>
                  {t('about.page.message.text1')}
                </div>
                <p className='inner-page-section__content__paragraph'>
                  {t('about.page.message.text2')}
                </p>
                <p className='inner-page-section__content__paragraph'>
                  {t('about.page.message.text3')}
                </p>
                <p className='inner-page-section__content__paragraph'>
                  {t('about.page.message.text4')}
                </p>
                <p className='inner-page-section__content__paragraph'>
                  {t('about.page.message.text5')}
                </p>
                <p className='inner-page-section__content__paragraph'>
                  {t('about.page.message.text6')}
                </p>
                <Founder />
              </div>
            </div>}
          </div>
        </div>
      </div>
    </Page>
  )
};

export default About;