import React from 'react';
import hero from '../../assets/images/hero.jpg';
import { useTranslation } from 'react-i18next';

const Hero = () => {
  const { t } = useTranslation();

  return (
    <div className='hero'>
      <div className='hero__media'>
        <img className='hero__media__img' src={hero} alt='Hero' />
      </div>
      <div className='hero__content container'>
        <h1 className='hero__content__title'>
          <div>{t('hero.title1')}</div>
          <div>{t('hero.title2')}</div>
        </h1>
        <a href="https://drive.google.com/file/d/1o6U8fPCnjlqlptK9JbfX91jM-FTCFEYu/view?usp=drive_link" target='_blank' rel='noopener noreferrer' className='hero__content__download'>
          <div className='hero__content__download__icon' />
          <div className='hero__content__download__text'>
            <div>{t('hero.download1')}</div>
            <div>{t('hero.download2')}</div>
          </div>
        </a>
      </div>
    </div>
  )
};

export default Hero;