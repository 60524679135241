import React from 'react';

const Hero = ({ title, lead, media }) => {
  return (
    <div className='page-hero' style={{background: `#008C44`}}>
      <div className='container'>
        <div className='page-hero__content'>
          <h1 className='page-hero__content__title'>
            {title}
          </h1>
          {lead && <div className='page-hero__content__lead inter-paragraph f-s-18'>
            {lead}
          </div>}
        </div>
        <div className='page-hero__media' style={{background: `#008C44 url(/img/hero/${media}) no-repeat 100% 0 / 630px 450px`, height: '450px'}} />
      </div>
    </div>
  )
};

export default Hero;