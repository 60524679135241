import React from 'react';
import Map from './Map';
import { useTranslation } from 'react-i18next';

const Points = () => {
  const { t } = useTranslation();

  return (
    <div className='points home__section'>
      <div className='container'>
        <div className='points__title'>
          {t('points.title')}
        </div>
        <div className='points__map'>
          <Map />
        </div>
        <div className='points__lead inter-paragraph' >
          {t('points.lead')}
        </div>
      </div>
    </div>
  )
};

export default Points;