import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { useTranslation } from 'react-i18next';

const socials = [
  { network: 'instagram', link: 'https://www.instagram.com/bai.elim/'},
  { network: 'facebook', link: 'https://www.facebook.com/bai.elim'},
  { network: 'whatsapp', link: 'https://wa.link/8dei1n'}
]

const options = [
  { value: 'ru', label: 'РУ' },
  { value: 'kg', label: 'KG' },
  { value: 'en', label: 'EN' },
];

const Header = () => {
  const { t, i18n } = useTranslation();
  const [toggled, setToggled] = useState(false);

  const menu = [
    { text: t('nav.about'), link: '/about'},
    { text: t('nav.products'), link: '/#products', external: true},
    { text: t('nav.sustainability'), link: '/sustainability'},
    { text: t('nav.companies'), link: '/companies'},
    { text: t('nav.awards'), link: '/awards'}
  ];

  const value = window.localStorage.getItem('locale') ? window.localStorage.getItem('locale') : 'ru';

  const onSelect = (_item) => {
    i18n.changeLanguage(_item.value);
    window.localStorage.setItem('locale', _item.value);
  }

  console.log(value)

  return (
    <header className={`header header--${i18n.language}`}>
      <div className='container'>
        <Link to='/' className='logo' />
        <div className={`header__navigation top ${toggled ? 'header__navigation--toggled' : ''}`}>
          <div className='header__navigation__mobile-close' onClick={() => setToggled(false)}>
            &#x2715;
          </div>
          <div className='header__menu'>
            {menu.map(item => {
              if (!item.external) {
                return (
                  <Link key={item.link} to={item.link} className='header__menu__item-link'>
                    {item.text}
                  </Link>
                )
              } else {
                return (
                  <a href={item.link} key={item.link} className='header__menu__item-link'>
                    {item.text}
                  </a>
                )
              }
            })}
          </div>
          <div className='header__socials'>
            {socials.map(item => {
              return (
                <a
                  href={item.link}
                  target='_blank'
                  rel='noopener noreferrer'
                  key={item.network} to={item.link}
                  className={`header__socials__item-link header__socials__item-link--${item.network}`}
                />
              )
            })}
          </div>
          <div className='header__locale'>
            <Dropdown options={options} onChange={onSelect} value={value} />
          </div>
        </div>
        <div className='header__burger' onClick={() => setToggled(true)}>
          <div className='header__burger__item' />
          <div className='header__burger__item' />
          <div className='header__burger__item' />
        </div>
        <div className='header__navigation__mobile-overlay' onClick={() => setToggled(false)} />
      </div>
    </header>
  )
};

export default Header;