import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const initialForm = {
  name: '',
  email: '',
  phone: '',
  msg: ''
}

const ContactForm = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    ...initialForm
  });

  const onChange = (value, key) => {

    setForm({
      ...form,
      [key]: value
    })
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      text: `Имя: ${form.name}\nEmail: ${form.email}\nТелефон: ${form.phone}\nСообщение: ${form.msg}`
    }
    const response = await fetch('https://baielim.kg:3003/send-email', {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    setLoading(false);

    if(response.ok) {
      alert('Ваше сообщение отправлено');
      setForm({
        ...initialForm
      });
    } else {
      alert('Ошибка при отправке сообщения! Попробуйте позже.');
    }
  };

  return (
    <div className='contact-form'>
      {loading && <div className='spinner'>
        <span className="loader"></span>
      </div>}
      <form onSubmit={submit}>
        <div className='contact-form__row'>
          <div className='contact-form__label'>
            {t('contacts.form.name')}
          </div>
          <input className='contact-form__control' value={form.name} type='text' onChange={(e) => onChange(e.target.value, 'name')} required />
        </div>
        <div className='contact-form__row'>
          <div className='contact-form__label'>
          {t('contacts.form.email')}
          </div>
          <input className='contact-form__control' value={form.email} type='email' onChange={(e) => onChange(e.target.value, 'email')} required />
        </div>
        <div className='contact-form__row'>
          <div className='contact-form__label'>
          {t('contacts.form.phone')}
          </div>
          <input className='contact-form__control' type='tel' value={form.phone} onChange={(e) => onChange(e.target.value, 'phone')} required />
        </div>
        <div className='contact-form__row'>
          <div className='contact-form__label'>
          {t('contacts.form.message')}
          </div>
          <textarea className='contact-form__control contact-form__control--textarea' value={form.msg} onChange={(e) => onChange(e.target.value, 'msg')} required />
        </div>
        <div className='contact-form__btn-wrapper'>
          <button className='contact-form__btn' type='submit'>{t('contacts.form.send')}</button>
        </div>
      </form>
    </div>
  )
};

export default ContactForm;