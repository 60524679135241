import React from 'react';
import Page from '../components/Page';
import Hero from '../components/hero/Hero';
import Breadcrumbs from '../components/breadcrumbs/Breadcrumbs'
import Founder from '../components/misc/Founder';
import { useTranslation } from 'react-i18next';

const partnersRu = [
  'Холдинг «Eurasian Foods Corporation», ТМ «3 желания» Казахстан.',
  'АО «Асыл Туз» Алматы, Казахстан.',
  'ТОО « Арай» Чимкент, Казахстан.',
  'ОOO "LOGISTIMPEX". Узбекистан.',
  'OOO "SAMO SERVIS PLUS OMAD". Узбекистан.',
  'ООО «УзХимиоИмпекс» Навои, Узбекистан.',
  'АО «Мозырь Соль» Минск, Беларусь.',
  'АО «Чишминский сахарный завод» Башкортостан, Россия.',
  'ООО " Кристалл" Тамбовская область,  Россия.',
  'ООО «ДонМаслоПродукт», Краснодар, Россия.',
  'АО «ЕвроХим» г. Москва, Россия.',
  'АO «БашХим» - пищевая сода Уфа, Башкирия, Россия.',
  'ООО «Геркулес», Алтайский край, Россия.',
  'ООО «Дары солнца», Алтайский край, Россия.',
  'ООО «Сибирская экспортная компания» Алтайский край, Россия.',
  'ООО «Кубанский рис», г. Кубань, Россия.'
]

const partnersEn =[
  'Holding "Eurasian Foods Corporation", TM "3 jelaniya" Kazakhstan.',
  'JSC "Asyl Tuz" Almaty, Kazakhstan.',
  'Arai LLP Chimkent, Kazakhstan.',
  'TASMAY LLP Almaty, Kazakhstan.',
  'LLC "LOGISTIMPEX". Uzbekistan.',
  'OOO "SAMO SERVIS PLUS OMAD". Uzbekistan.',
  'LLC "UzKhimioImpex" Navoi, Uzbekistan.',
  'JSC "Mozyr Sol" Minsk, Belarus.',
  'JSC "Chishminsky Sugar Plant" Bashkortostan, Russia.',
  'LLC "Crystal" Tambov region, Russia.',
  'DonMasloProduct LLC, Krasnodar, Russia.',
  'JSC EuroChim, Moscow, Russia.',
  'JSC "BashKhim" - baking soda Ufa, Bashkiria, Russia.',
  'LLC "Hercules", Altai region, Russia.',
  'LLC "Dary soltsa, Altai Territory, Russia.',
  'LLC "Siberian Export Company" Altai Territory, Russia.',
  'LLC "Kuban Rice", Kuban, Russia.'
]

const Sustainability = () => {
  const { t, i18n } = useTranslation();

  const items = [
    {icon: 'sustainability-sample-1.png', title: t('sustain.page.aims.title1'), text: t('sustain.page.aims.text1')},
    {icon: 'sustainability-sample-2.png', title: t('sustain.page.aims.title2'), text: t('sustain.page.aims.text2')},
    {icon: 'sustainability-sample-3.png', title: t('sustain.page.aims.title3'), text: t('sustain.page.aims.text3')},
    {icon: 'sustainability-sample-4.png', title: t('sustain.page.aims.title4'), text: t('sustain.page.aims.text4')},
    {icon: 'sustainability-sample-5.png', title: t('sustain.page.aims.title5'), text: t('sustain.page.aims.text5')},
  ];

  const partners = i18n && i18n.language == 'en' ? partnersEn :partnersRu;

  return (
    <Page>
      <div className='sustainability'>
        <Hero
          media='hero-sample-sustainability.jpg'
          title={t('sustain.page.hero_title')}
          lead={t('sustain.page.hero_lead')}
        />
        <div className='page-main'>
          <Breadcrumbs items={[{text: t('nav.sustainability')}]} />
          <div className='container'>
            <div className='sustainability__intro'>
              {/* <div className='sustainability__intro__video-frame'> */}
                {/* <video className='sustainability__intro__video-frame__player' src={'/vid.mp4'} controls/> */}
              {/* </div> */}
              <div className='sustainability__intro__content'>
                <div className='sustainability__intro__content__text inter-paragraph'>
                  {t('sustain.page.intro_text')}
                </div>
                <Founder />
              </div>
            </div>
            <div className='sustainability__aims'>
              {items.map((aim, index) => {
                return (
                  <div key={index} className='sustainability__aims__item'>
                    <div className='sustainability__aims__item__media'>
                      <img src={`/img/sustainability/${aim.icon}`} className='sustainability__aims__item__media__img' />
                    </div>
                    <div className='sustainability__aims__item__content'>
                      <div className='sustainability__aims__item__content__title'>
                        {aim.title}
                      </div>
                      <div className='sustainability__aims__item__content__text inter-paragraph f-s-18'>
                        {aim.text}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className='sustainability__partners'>
              <div className='sustainability__partners__title'>
                {t('substain.page.partners.title')}
              </div>
              <div className='sustainability__partners__content'>
                <p className='sustainability__partners__content__paragraph inter-paragraph f-s-18'>
                  {t('substain.page.partners.lead1')}
                </p>
                <p className='sustainability__partners__content__paragraph inter-paragraph f-s-18'>
                  {t('substain.page.partners.lead2')}
                </p>
              </div>
              <div className='sustainability__partners__list'>
                {partners.map((item, index) => {
                  return (
                    <div key={`partners-${index}`} className='sustainability__partners__list__item inter-paragraph f-s-18'>
                      {item}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
};

export default Sustainability;