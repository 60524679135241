import React from 'react';
import Page from '../components/Page';
import Hero from '../components/hero/Hero';
import Breadcrumbs from '../components/breadcrumbs/Breadcrumbs';
import CompaniesList from '../components/companies/CompaniesList';
import { useTranslation } from 'react-i18next';

const Companies = () => {
  const { t } = useTranslation();
  return (
    <Page>
      <div className='companies-page'>
        <Hero
          media='hero-sample-companies.jpg'
          title={t('companies.page.hero_title')}
        />
        <div className='page-main'>
          <Breadcrumbs items={[{text: t('companies.page.hero_title')}]} />
          <div className='companies-page-wrapper'>
            <div className='container'>
              <div className='companies__lead inter-paragraph f-s-18'>
                {t('companies.page.lead')}
              </div>
              <CompaniesList />
              <div className='inner-page-section'>
                <div className='inner-page-section__media' />
                <div className='inner-page-section__content'>
                  <div className='inner-page-section__content__title'>
                    {t('companies.page.bottom.title')}
                  </div>
                  <div className='inner-page-section__content__paragraph inter-paragraph f-s-18'>
                    {t('companies.page.bottom.text1')}
                  </div>
                  <div className='inner-page-section__content__paragraph inter-paragraph f-s-18'>
                    {t('companies.page.bottom.text2')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
};

export default Companies;