import React from 'react';
import { useTranslation } from 'react-i18next';

const ProductsList = () => {
  const { t } = useTranslation();

  const products = [
    {img: '/product-sample-1.png', title: t('products.list.oil')},
    {img: '/product-sample-2.png', title: t('products.list.sugar')},
    {img: '/product-sample-3.png', title: t('products.list.rice')},
    {img: '/product-sample-4.png', title: t('products.list.veg')},
    {img: '/product-sample-5.png', title: t('products.list.salt')},
    {img: '/product-sample-6.png', title: t('products.list.souce')},
    {img: '/product-sample-7.png', title: t('products.list.vinegar')},
    {img: '/product-sample-8.png', title: t('products.list.jam')},
  ]

  return (
    <div className='products__list'>
      {products.map((product, index) => {
        return (
          <div key={index} className='products__item'>
            <div className='products__item__media'>
              <img src={`/img/home/products/${product.img}`} className='products__item__media__img' alt={product.title} />
            </div>
            <div className='products__item__title'>
              {product.title}
            </div>
          </div>
        )
      })}
    </div>
  )
};

export default ProductsList;