import React from 'react';
import Header from '../header/Header';

const Footer = () => {
  return (
    <footer className='footer'>
      <Header />
    </footer>
  )
};

export default Footer