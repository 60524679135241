import React from 'react';
import ContactForm from './ContactForm';
import { useTranslation } from 'react-i18next';

const items = [
  {text: '+996 (770) 711 888', icon: 'phone'},
  {text: '+996 (777) 447 711', icon: 'phone'},
  {text: '+996 (312) 463-463', icon: 'phone'},
  {text: '+996 (312) 463-463', icon: 'phone'},
  {text: 'baielim.eco@gmail.com', icon: 'mail'},
  {text: 'ecobaielim@gmail.com', icon: 'mail'},
  {text: 'Бишкек, ул. Интергельпо 1/38, КР', icon: 'marker'},
  
]

const Contact = () => {
  const { t } = useTranslation();

  return (
    <div className='contact'>
      <div className='container'>
        <div className='contact__section contact__content'>
          <div className='contact__section__title'>
            {t('contacts.title')}
          </div>
          <div className='contact__content__text'>
            <div>{t('contacts.text1')}</div>
            <div>{t('contacts.text2')}</div>
          </div>
          <div className='contact__content__items'>
            {items.map((item, index) => {
              if (item.icon ==='phone') {
                return (
                  <a href={`tel:${item.text}`} key={index} className='contact__content__item'>
                    <div className={`contact__content__item__icon contact__content__item__icon--${item.icon}`} />
                    <div className='contact__content__item__text'>
                      {item.text}
                    </div>
                  </a>
                )
              } else if (item.icon === 'mail') {
                return (
                  <a href={`mailto:${item.text}`} key={index} className='contact__content__item'>
                    <div className={`contact__content__item__icon contact__content__item__icon--${item.icon}`} />
                    <div className='contact__content__item__text'>
                      {item.text}
                    </div>
                  </a>
                )
              } else {
                return (
                  <div key={index} className='contact__content__item'>
                    <div className={`contact__content__item__icon contact__content__item__icon--${item.icon}`} />
                    <div className='contact__content__item__text'>
                      {t('contacts.address')}
                    </div>
                  </div>
                )
              }
            })}
          </div>
        </div>
        <div className='contact__section'>
          <div className='contact__section__title contact__section__title--form'>
            {t('contacts.form_title')}
          </div>
          <ContactForm />
        </div>
      </div>
    </div>
  )
};

export default Contact;