import React from 'react';
import { Link } from 'react-router-dom';
import img from '../../assets/images/history-sample.png';
import img2 from '../../assets/images/history-sample-2.png';
import { useTranslation } from 'react-i18next';

const History = () => {
  const { t } = useTranslation();

  return (
    <div className='history home__section'>
      <div className='container'>
        <div className='history__media'>
          <img src={img} className='history__media__img' alt={t('history.title')} />
        </div>
        <div className='history__content'>
          <div className='history__content__media show-mobile'>
            <img src={img2} className='history__content__media__img' alt={t('history.title')} />
          </div>
          <div className='history__content__text'>
            {t('history.title')}
          </div>
          <Link to='/about' className='home__learn-more'>
            <span className='home__learn-more__text'>{t('history.btn')}</span>
            <span className='home__learn-more__icon' />
          </Link>
          <div className='history__content__media'>
            <img src={img2} className='history__content__media__img' alt={t('history.title')} />
          </div>
        </div>
      </div>
    </div>
  )
};

export default History;