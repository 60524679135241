import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Breadcrumbs = ({ items=[] }) => {
  const { t } = useTranslation();

  return (
    <div className='breadcrumbs container'>
      <Link to='/' className='breadcrumbs__item breadcrumbs__item--link breadcrumbs__item--home'>
        {t('nav.main')}
      </Link>
      {items.map((item, index) => {
        if (item.link) {
          return (
            <Link key={index} to={item.link} className='breadcrumbs__item breadcrumbs__item--link'>
              {item.text}
            </Link>
          )
        } else {
          return (
            <div key={index} className='breadcrumbs__item breadcrumbs__item--text'>
              {item.text}
            </div>
          )
        }
      })}
    </div>
  )
};

export default Breadcrumbs