import React from 'react';
import Page from '../components/Page';
import Hero from '../components/hero/Hero';
import Breadcrumbs from '../components/breadcrumbs/Breadcrumbs'
import { useTranslation } from 'react-i18next';

const Awards = () => {
  const { t } = useTranslation();
  const certificates = [
    {strong: t('awards.page.certificates_bold1'), text: t('awards.page.certificates_text1'), link: 'https://drive.google.com/file/d/1ILBdDtbUS_gq2eiHRo2V1IgalQlXQTZC/view?usp=sharing'},
    {strong: t('awards.page.certificates_bold2'), text: t('awards.page.certificates_text2'), link: 'https://drive.google.com/file/d/1tVAGMi63KJWQ4H-4523sQ9ti6k4mxxWy/view?usp=drive_link'},
    {strong: t('awards.page.certificates_bold3'), text: t('awards.page.certificates_text3'), link: 'https://drive.google.com/file/d/1tPpxaHIWVxsJgiqtvHMQ2cn0Fvi-8Cwu/view?usp=sharing'},
    {strong: t('awards.page.certificates_bold4'), text: t('awards.page.certificates_text4'), link: 'https://drive.google.com/file/d/1q-XdQOkTjJqPWycsLMCQmom5sfDWKutF/view?usp=sharing'},
    {strong: t('awards.page.certificates_bold5'), text: t('awards.page.certificates_text5'), link: 'https://drive.google.com/file/d/1ob_QLX4J9n9uZ2i5gocHQNaLAFECWfdz/view?usp=sharing'},
  ]
  return (
    <Page>
      <div className='awards'>
        <Hero
          media='hero-sample-awards.jpg'
          title={t('awards.page.hero_title')}
          lead={t('awards.page.hero_lead')}
        />
        <div className='page-main'>
          <Breadcrumbs items={[{text: t('nav.awards')}]} />
          <div className='container'>
            <div className='awards__lead inter-paragraph f-s-18'>
              {t('awards.page.lead')}
            </div>
            <div className='awards__certificates'>
              {certificates.map((cert, index) => {
                return (
                  <div key={index} className='awards__certificates__item'>
                    <div><strong>{cert.strong}</strong> {cert.text}</div>
                    <a href={cert.link} target='_blank' rel='noopener noreferrer' className='awards__certificates__item__btn'>{t('awards.page.download')}</a>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
};

export default Awards;