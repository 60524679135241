import React from 'react';
import ProductsList from '../../components/products/List';
import { useTranslation } from 'react-i18next';

const Products = () => {
  const { t } = useTranslation();

  return (
    <div className='products home__section' id='products'>
      <div className='container'>
        <div className='products__title home__section__title'>
          {t('products.title')}
        </div>
        <ProductsList />
        <div className='products__download'>
          <a href="https://drive.google.com/file/d/1o6U8fPCnjlqlptK9JbfX91jM-FTCFEYu/view?usp=drive_link" target="_blank" rel='noopener noreferrer' className='products__download__btn'>
            <span className='products__download__btn__text'>{t('products.btn')}</span>
            <span className='products__download__btn__icon' />
          </a>
          <div className='products__download__text'>{t('products.text')}</div>
        </div>
      </div>
    </div>
  )
}

export default Products;