import React from 'react';
import CompaniesList from '../companies/CompaniesList';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Companies = () => {
  const { t } = useTranslation();

  return (
    <div className='companies home__section'>
      <div className='container'>
        <div className='companies__top'>
          <div className='home__section__title'>
            {t('companies.title')}
          </div>
          <Link to='/companies' className='home__learn-more'>
            <span className='home__learn-more__text'>{t('companies.btn')}</span>
            <span className='home__learn-more__icon' />
          </Link>
        </div>
        <div className='companies__lead inter-paragraph f-s-18'>
          {t('companies.lead')}
        </div>
        <CompaniesList />
      </div>
    </div>
  )
};

export default Companies;