import React from 'react';
import { useTranslation } from 'react-i18next';

const Copyright = () => {
  const { t } = useTranslation();
  return (
    <div className='copyright'>
      <div className='container'>
        {t('copyright')}
      </div>
    </div>
  )
};

export default Copyright